<template>
	<screen class="donor-list-view" size="large">
		<h1 slot="title">Profile Views</h1>
		<template slot="buttons">
			<ui-button>Export CSV</ui-button>
		</template>
		<div slot="chart">
			<chart :chartData="graph"></chart>
		</div>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get } from '@/utilities'
import table from '@/mixins/table'

import UiButton from '../../ui/Button'
import Screen from '../../ui/Screen'
import Chart from '../../ui/Chart'

export default {
	name: 'donations-report-view',
	components: { UiButton, Screen, Chart },
	mixins: [table],
	computed: {
		...mapState({
			items: ({ reportList }) => reportList.items,
			total: ({ reportList }) => reportList.total,
			graph: ({ reportList }) => reportList.graph
		})
	},
	head: {
		title: {
			inner: 'Reports - Donations'
		}
	},
	methods: {
		...mapActions(['getProfileReport']),
		paramsChanged(params) {
			this.getDonationsReport(params)
		},
		get
	},
	mounted() {
		this.getProfileReport('cfceac9-f6da-4f6c-925e-98797e1a6742')
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';
</style>
